import {useDispatch, useSelector} from 'react-redux'
import {ArrowClockwise, ArrowLeftCircle, ArrowRightCircle, Phone, Reset, Switch} from '../Icons'
import {
    getSectionNotes,
    getStringTranslations,
    setIsAgentNoteOpen,
} from '../../state/settingsSlice'
import {Col, Row, Modal, Form} from 'react-bootstrap'
import {isConsumer} from '../../functions'
import {Sections} from './Sections'
import {LeaderBoard} from './LeaderBoard'
import './header.scss'
import {FAQs} from './FAQs'
import {useState} from 'react'
import {changeScript, resetScript} from '../../state/embeddedSlice'


const ChangeScript = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()

    const onChange = (e) => {
        dispatch(changeScript(e.target.value))
        setIsOpen(false)
    }

    const model = <>
        <Modal show={isOpen} onHide={() => setIsOpen(false)} backdrop="static">
            <Modal.Title>
                <h4 style={{padding: '1rem 0 0 1rem'}}>Change script</h4>
            </Modal.Title>
            <Modal.Body>
                <Form.Group>
                    <Form.Select onChange={onChange}>
                        <option>Select script</option>
                        <option value="consumer">Consumer Residential</option>
                        <option value="dealer">Dealer</option>
                        <option value="referral">Referral</option>
                        <option value="consumerSpanish">Consumer Residential Spanish</option>
                        <option value="business">Business</option>
                        <option value="mexico">Mexico</option>
                        <option value="pr">Puerto Rico</option>
                    </Form.Select>
                </Form.Group>
            </Modal.Body>
        </Modal>
    </>

    return <>
        <span onClick={() => setIsOpen(true)}>
                <Switch/>
        </span>
        {model}
    </>
}

export function Header() {
    const firstName = useSelector(state => state.customer.data.serviceAddress.firstName) ?? ''
    const lastName = useSelector(state => state.customer.data.serviceAddress.lastName) ?? ''
    const phoneNumber = useSelector(state => state.customer.data.serviceAddress.phoneNumber) ?? ''
    const embeddedPhone = useSelector(state => state.settings.embeddedPhone)
    const agentIdEmbedded = useSelector(state => state.embedded.agentId)
    const offerId = useSelector(state => {
        if (isConsumer(state.customer.data.script)) {
            return state.customer.data.offerId ?? ''
        }
        return state.customer.data.smeOfferId ?? ''
    })
    const caseId = useSelector(state => state.customer.data.caseId) ?? ''
    const agentFirstname = useSelector(state => state.customer.data.agentFirstname)
    const agentId = useSelector(state => state.customer.data.agentId)
    const mediaType = useSelector(state => state.customer.data.mediaType) ?? ''
    const isAgentNoteOpen = useSelector(state => state.settings.isAgentNoteOpen)
    const dispatch = useDispatch()

    const openCloseAgentPanel = () => {
        dispatch(setIsAgentNoteOpen(!isAgentNoteOpen))
    }

    const refreshLabels = () => {
        dispatch(getStringTranslations())
        dispatch(getSectionNotes())
    }
    const onOpenWebPhone = () => {
        // dispatch(setOpenEmbeddedPhone(!openEmbeddedPhone))
        window.open(process.env.REACT_APP_EMBEDDED_ALVARIA_ORIGIN)
    }

    let embeddedPhoneComps
    let agentIdDebug
    if (embeddedPhone) {
        embeddedPhoneComps = <>
            <span onClick={onOpenWebPhone}>
                <Phone/>
            </span>
            <span onClick={() => dispatch(resetScript())}>
                <Reset/>
            </span>
            <ChangeScript/>
        </>

        agentIdDebug = <span>Agent Id: {agentIdEmbedded}</span>
    }

    return (
        <header className="header">
            <Row>
                <Col md={4}>
                    <span>{firstName + ' ' + lastName}</span><br/>
                    <span
                        className="info-box">{agentFirstname ? agentFirstname + ' - ' + agentId + ' | ' : ''} {mediaType}{phoneNumber ? ' | ' + phoneNumber : null}{offerId ? ' | ' + offerId : null}</span>
                </Col>
                <Col md={8}>
                    <div className="case-info">
                        {caseId ?  <span>Case Id: {caseId}</span> : agentIdDebug}
                        {embeddedPhoneComps}
                        <span onClick={openCloseAgentPanel}>{isAgentNoteOpen ? <ArrowRightCircle/> :
                            <ArrowLeftCircle/>}</span>
                        <span onClick={refreshLabels}>
                            <ArrowClockwise/>
                        </span>
                        <LeaderBoard/>
                        <FAQs/>
                    </div>
                </Col>
            </Row>
            {caseId ? <Sections/> : null}
        </header>
    )
}

