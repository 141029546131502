import * as Sentry from '@sentry/react'
import {useDispatch, useSelector} from 'react-redux'
import {useInputState, useLabels} from '../../functions'
import {
    login,
    setAppLoadingState,
    setIsAgentNoteOpen,
    setLangScript,
    setNotesSection,
    setOrderType
} from '../../state/settingsSlice'
import {createNewCase, reAssignCase} from '../../state/customerAsyncActions'
import {Alert, Button, Col, Container, Form, Row, Table} from 'react-bootstrap'
import {FormLabel} from '../Utilities/Form'
import {onAfterCaseLoad} from './functions'
import {setEmbeddedShowNewCase} from '../../state/embeddedSlice'

export const CaseSelector = (props) => {
    const labels = useLabels()
    const existingCases = useSelector(state => state.customer.cases)
    const dispatch = useDispatch()

    const onSelect = async (cs) => {
        dispatch(setAppLoadingState('pending'))
        const ret = await dispatch(reAssignCase({args: props.args, data: cs}))
        const caseData = ret.payload
        await onAfterCaseLoad(dispatch, caseData)
        dispatch(setLangScript(caseData?.script))
        dispatch(setOrderType('new'))
        dispatch(setAppLoadingState(''))
    }

    const onCreateNewCase = async () => {
        dispatch(setAppLoadingState('pending'))
        const caseData = (await dispatch(createNewCase(props.args))).payload
        dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
        dispatch(setOrderType('new'))
        dispatch(setAppLoadingState(''))
        dispatch(setEmbeddedShowNewCase(false))
        dispatch(setIsAgentNoteOpen(true))
    }

    return <>
        <Row className="mt-5">
            <Col>
                <h5>{labels.caseSelectorInfo}</h5>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Button onClick={onCreateNewCase}>{labels.createNewCase}</Button>
            </Col>
        </Row>
        <Row className="mt-5 mb-5 cases-table">
            <Col>
                <Table hover>
                    <thead>
                    <tr>
                        <th>{labels.caseId}</th>
                        <th>{labels.addressFirstName}</th>
                        <th>{labels.addressLastName}</th>
                        <th>{labels.addressPhoneNumber}</th>
                        <th>Script</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {existingCases.map(cs => <tr key={cs.caseId}>
                        <td>{cs.caseId}</td>
                        <td>{cs.serviceAddress?.firstName}</td>
                        <td>{cs.serviceAddress?.lastName}</td>
                        <td>{cs.phoneNumber}</td>
                        <td>{cs.script}</td>
                        <td>
                            <Button onClick={() => onSelect(cs)}>{labels.copyAndCreateCase}</Button>
                        </td>
                    </tr>)}
                    </tbody>
                </Table>
            </Col>
        </Row>
    </>
}


export const LoginForm = (props) => {
    const [username, setUsername] = useInputState('')
    const [password, setPassword] = useInputState('')
    const loginError = useSelector(state => state.settings.loginError)
    const loginState = useSelector(state => state.settings.loginState)
    const dispatch = useDispatch()

    const onClick = async () => {
        const ret = await dispatch(login({username, password}))
        const [ok] = ret.payload
        if (ok) {
            Sentry.setUser({email: username})
            window.localStorage.creds = JSON.stringify({username, password})
            if (props.onLogin) {
                props.onLogin()
            }
        }
    }

    return <>
        {loginError !== '' ? <Row className="mt-3 mb-3">
            <Col md={{span: 4, offset: 4}}>
                <Alert>{loginError}</Alert>
            </Col>
        </Row> : null}
        <Row className="mt-3">
            <Col className="login-form" md={{span: 4, offset: 4}}>
                <Form.Group>
                    <FormLabel bold={true}>Username</FormLabel>
                    <Form.Control value={username} onChange={setUsername}/>
                </Form.Group>
                <Form.Group>
                    <FormLabel bold={true}>Password</FormLabel>
                    <Form.Control type="password" value={password} onChange={setPassword}/>
                </Form.Group>
                <Button className="mt-3" onClick={onClick}
                        disabled={loginState === 'pending' || !username || !password}>
                    Submit
                </Button>
            </Col>
        </Row>
    </>
}


export const ErrorPage = (props) => {
    return (<Container fluid={true}>
        <Row className="error">
            <Col sm={12} md={6}>
                <span>
                    {props.message}
                </span>
            </Col>
        </Row>
    </Container>)
}

