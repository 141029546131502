import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {
    fetchCCInfo,
    getCCInfoFromTwillio,
    getTwilioPhoneNumber,
    pauseOrResumeRecording,
    storeCCInfo,
    writeCCAuditTrail,
} from '../../state/customerAsyncActions'
import {setBillingAddress, setCreditCard} from '../../state/customerSlice'
import {connect} from '../Notes/wrapper'
import {useEffect, useState} from 'react'
import {setAppLoadingState} from '../../state/settingsSlice'
import {getNote, isInputValid, isMexico, useLabels} from '../../functions'
import {validateCreditCard} from '../../state/validationSlice'
import {getSectionContent} from '../Notes'

export const CreditCard = () => {
    const labels = useLabels()
    const dispatch = useDispatch()
    const address = useSelector(state => state.customer.data.billingAddress)
    const creditCard = useSelector(state => state.customer.data.creditCard)
    const [showModal, setShowModal] = useState(false)
    const [whoEnterCreditCart, setWhoEnterCreditCart] = useState('')
    const cartState = useSelector(state => state.customer.cartState)
    const twilioPhoneNumber = useSelector(state => state.customer.twillioNumber)
    const caseId = useSelector(state => state.customer.data.caseId)
    const program = useSelector(state => state.customer.data.program)
    const agentId = useSelector(state => state.customer.data.agentId)
    const [showCCInfoError, setShowCCInfoError] = useState(false)
    const cashOrCreditCard = useSelector(state => state.customer.data.cashOrCreditCard)
    const orderPim = useSelector(state => state.customer.data.orderPim)
    const creditCardValidation = useSelector(state => state.validation.creditCard)
    const notes = useSelector(state => state.settings.notes)
    const nonPCI = useSelector(state => state.settings.nonPCI)


    useEffect(() => {
        dispatch(getTwilioPhoneNumber())
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])

    let sessionId
    if (caseId) {
        sessionId = caseId.substring(1)
    }

    function customerEnteredCC() {
        return cashOrCreditCard === 'customer submitted CC'
    }

    const showCreditCarInfo = () => {
        return !customerEnteredCC() || (orderPim && orderPim !== '5')
    }

    const onClickStoreCC = async () => {
        setShowModal(false)
        const ret = await dispatch(validateCreditCard())
        if (ret.payload) {
            return
        }
        dispatch(setAppLoadingState('pending'))
        await dispatch(storeCCInfo())
        dispatch(setAppLoadingState(''))
        dispatch(pauseOrResumeRecording('resume'))
    }

    const setAddress = (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        dispatch(setBillingAddress(payload))
    }

    const onCreditCard = (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        dispatch(setCreditCard(payload))
    }


    const months = [...Array(12).keys()].map(i => (i + 1).toString().padStart(2, '0'))
    const years = [...Array(9).keys()].map(i => (i + (new Date()).getFullYear()).toString().substring(2, 4))


    function getForm() {
        let component
        if (whoEnterCreditCart === 'agent' && showCreditCarInfo()) {
            component = <>
                <Row>
                    <Col className="mb-3">
                        <Form.Group>
                            <FormLabel required={true}
                                       bold={true}>{labels.creditCardNumber}</FormLabel>
                            <Form.Control type={isMexico(program) ? "password" : "text"} value={creditCard.creditNumber}
                                          as="input" name="creditNumber"
                                          onChange={onCreditCard}
                                          isInvalid={!isInputValid(creditCardValidation, 'creditNumber')}/>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} className="mb-3">
                        <Form.Group>
                            <FormLabel required={true} bold={true}>{labels.expirationMonth}</FormLabel>
                            <Form.Select value={creditCard.expireMonth} name="expireMonth" onChange={onCreditCard}
                                         isInvalid={!isInputValid(creditCardValidation, 'expireMonth')}>
                                <option></option>
                                {months.map(month => <option key={month} value={month}>{month}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-3">
                        <Form.Group>
                            <FormLabel required={true} bold={true}>{labels.expirationYear}</FormLabel>
                            <Form.Select value={creditCard.expireYear} onChange={onCreditCard} name="expireYear"
                                         isInvalid={!isInputValid(creditCardValidation, 'expireYear')}>
                                <option></option>
                                {years.map(year => <option key={year} value={year}>{year}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-3">
                        <Form.Group>
                            <FormLabel required={true} bold={true}>{labels.CVV}</FormLabel>
                            <Form.Control value={creditCard.cvv} as="input" name="cvv" onChange={onCreditCard}
                                          isInvalid={!isInputValid(creditCardValidation, 'cvv')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        } else if (whoEnterCreditCart === 'customer') {
            const note = getNote(notes, 'ivr-instructions')
            component = <Row>
                <Col>
                    {getSectionContent(note, {sessionId, twilioPhoneNumber, agentId})}
                </Col>
            </Row>
        }

        const onReset = () => {
            setShowCCInfoError(false)
            setWhoEnterCreditCart('')
            if (whoEnterCreditCart === 'agent') {
                dispatch(pauseOrResumeRecording('resume'))
            }
            dispatch(setCreditCard({creditNumber: '', cvv: ''}))
        }
        return <>
            {customerEnteredCC() ? <Alert>{labels.customerEnteredCCInfoNotice}</Alert> : null}
            <Row>
                <Col className="mb-3" sm={12} md={6}>
                    <Form.Group>
                        <FormLabel required={true} bold={true}>{labels.addressFirstName}</FormLabel>
                        <Form.Control value={address.firstName} type="text" name="firstName" onChange={setAddress}/>
                    </Form.Group>
                </Col>
                <Col className="mb-3" sm={12} md={6}>
                    <Form.Group>
                        <FormLabel required={true} bold={true}>{labels.addressLastName}</FormLabel>
                        <Form.Control value={address.lastName} type="text" name="lastName" onChange={setAddress}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    <Form.Group>
                        <FormLabel required={true} bold={true}>{labels.typeOfCreditCard}</FormLabel>
                        <Form.Select name="paymentType" onChange={onCreditCard} value={creditCard.paymentType}
                                     isInvalid={!isInputValid(creditCardValidation, 'paymentType')}>
                            <option></option>
                            <option value="Visa">Visa</option>
                            <option value="MasterCard">MasterCard</option>
                            <option value="AmericanExpress">AmericanExpress</option>
                            {!isMexico(program) ? <option value="Discover">Discover</option> : null}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
            {component}
            {showCCInfoError ? <Row>
                <Col>
                    <Alert variant="danger">{labels.errorWhenLoadingCCInfoFromTwillio}</Alert>
                </Col>
            </Row> : null}
            <Row className="mt-5">
                <Col md={{span: 3, offset: 3}}>
                    <Button onClick={onReset}>Reset</Button>
                </Col>
                <Col md={{span: 3}}>
                    <Button onClick={onClickStoreCC}>
                        {labels.save}
                    </Button>
                </Col>
            </Row>
        </>
    }

    function selectWhoEntersCC() {
        const onClick1 = () => {
            setWhoEnterCreditCart('agent')
            dispatch(pauseOrResumeRecording('pause'))
        }
        return <Row>
            <Col>
                <FormLabel bold={true}>{labels.whoEnterCreditCardInfo}</FormLabel>
                <FormCheck type="radio" label={labels.agentEnterCreditCard} checked={whoEnterCreditCart === 'agent'}
                           onClick={onClick1}/>
                <FormCheck type="radio" value="customer" label={labels.customerEnterCreditCardPhoneSystem}
                           checked={whoEnterCreditCart === 'customer'}
                           onClick={() => setWhoEnterCreditCart('customer')}/>
            </Col>
        </Row>
    }


    const onHide = async () => {
        if (whoEnterCreditCart === 'customer') {
            setShowCCInfoError(false)
            const ret = await dispatch(getCCInfoFromTwillio())
            if (ret.payload.error) {
                setShowCCInfoError(true)
                return
            }
        }
        setShowModal(false)
        if (whoEnterCreditCart === 'agent') {
            dispatch(pauseOrResumeRecording('resume'))
        }
        setWhoEnterCreditCart('')
    }

    if (!cartState || cartState !== 'done') {
        return <></>
    }

    let component
    if (whoEnterCreditCart === '' && customerEnteredCC()) {
        setWhoEnterCreditCart('agent')
    } else if (whoEnterCreditCart === '') {
        component = selectWhoEntersCC()
    } else {
        component = getForm()
    }

    const onOpen = async () => {
        if (nonPCI) {
            dispatch(setAppLoadingState('pending'))
            await dispatch(fetchCCInfo({caseId, program}))
            dispatch(setAppLoadingState(''))
            return
        }
        await dispatch(writeCCAuditTrail({caseId}))
        dispatch(setAppLoadingState('pending'))
        await dispatch(fetchCCInfo({caseId, program}))
        dispatch(setAppLoadingState(''))
        setShowModal(true)
    }

    return <>
        <Row className="mt-5">
            <Col md={{span: 3, offset: 5}}>
                <Button onClick={onOpen}>{labels.creditCardButton}</Button>
            </Col>
        </Row>
        <Modal size="lg" show={showModal} onHide={onHide}>
            <Modal.Header closeButton/>
            <Modal.Body>
                {component}
            </Modal.Body>
        </Modal>
    </>
}

export default connect(CreditCard, 'collect-credit-card')
