import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {setAdditionalArgs, resetDataState, setProgram, openAlert} from './customerSlice'
import {getSectionNotes, setAppLoadingState, detectLang, setIsAgentNoteOpen} from './settingsSlice'
import {checkExistingCases} from './customerAsyncActions'


export const getScriptInfo = async ({contactid, agent_id, iccalltype, workType, ani, channel}) => {
    const path = `/api/v1/script_info?umid=${contactid}&agent_id=${agent_id}&direction=${iccalltype}&workType=${workType}&ani=${ani}&channel=${channel}`
    const url = process.env.REACT_APP_CRM_NEW_BASE_URL + path
    const response = await fetch(url)
    if (response.ok) {
        return response.json()
    }
    if (response.status === 404) {
        const data = await response.json()
        throw new Error(`An invalid work type is selected for this call: ${data.workType}. For outbound calls make sure you select on of the valid outbound work types`)
    }
    return null
}

const setCallAnswered = async ({contactid, agent_id, channel}) => {
    const res = await fetch(`${process.env.REACT_APP_CRM_NEW_BASE_URL}/api/v1/call/status/${contactid}/answered/${agent_id}?channel=${channel}`, {
        method: 'post',
        mode: 'no-cors'
    })
    return res.ok
}


export const handleNewCall = createAsyncThunk('customer/embedded/new_call', async (args, thunkAPI) => {
    const {dispatch} = thunkAPI
    await dispatch(handleEndCallTask())

    dispatch(setAppLoadingState('pending'))
    let ret
    try {
        ret = await getScriptInfo(args)
    } catch (e) {
        dispatch(openAlert(e.message))
        dispatch(setAppLoadingState(''))
        return
    }

    if (ret == null) {
        throw Error(`could not find script: ${args.agent_id} and ${args.tfn}`)
    }

    args = {...args, ...ret}

    await dispatch(handleNewArgs(args))
    //
    dispatch(setAppLoadingState(''))
})

export const handleActiveCall = createAsyncThunk('customer/embedded/active_call', async (args, thunkAPI) => {
    const {embedded} = thunkAPI.getState()
    await setCallAnswered(args)
    if (embedded.args === null) {
        //probably an outbound call
        const {dispatch} = thunkAPI
        await dispatch(handleNewCall(args))
    }
})

export const handleNewArgs = createAsyncThunk('customer/embedded/new_args', async (args, thunkAPI) => {
    const {dispatch} = thunkAPI
    await dispatch(setProgram(args.program))
    await dispatch(detectLang({language: 'english', program: args.program}))
    await dispatch(getSectionNotes())
    dispatch(setAdditionalArgs(args))
    await dispatch(checkExistingCases(args))
    return args
})

export const handleCallClear = createAsyncThunk('customer/embedded/call_clear', async (args, thunkAPI) => {
    const {dispatch} = thunkAPI
    const {embedded} = thunkAPI.getState()
    if (embedded.args?.callId === args.callId) {
        dispatch(handleEndCallTask())
    }
})

export const handleEndCallTask = createAsyncThunk('customer/embedded/end_call_task', async (arg, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    dispatch(resetDataState())
    dispatch(setIsAgentNoteOpen(false))
})

export const changeScript = createAsyncThunk('customer/embedded/change_script', async (arg, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    const {embedded} = thunkAPI.getState()
    if (!embedded.args) {
        return
    }

    const scriptsMap = {
        consumer: {
            script: 'Consumer Residential',
            language: 'english'
        },
        dealer: {
            script: 'Dealer',
            language: 'english-dealer'
        },
        referral: {
            script: 'Referral',
            language: 'english-dealer'
        },
        consumerSpanish: {
            script: 'Consumer Residential',
            language: 'spanish'
        },
        business: {
            script: 'Business',
            language: 'english'
        },
        mexico: {
            program: 'Mexico',
        },
        pr: {
            script: 'Consumer Residential',
            language: 'consumer_pr1',
            region: 'PR'
        }
    }

    const args = {...embedded.args, ...scriptsMap[arg]}
    await dispatch(resetDataState())
    await dispatch(setIsAgentNoteOpen(false))
    await dispatch(handleNewArgs(args))
})

export const resetScript = createAsyncThunk('customer/embedded/reset_script', async (arg, thunkAPI) => {
    const {embedded} = thunkAPI.getState()
    if (!embedded.args) {
        return
    }
    const dispatch = thunkAPI.dispatch
    const args = {...embedded.args}

    await dispatch(resetDataState())
    await dispatch(setIsAgentNoteOpen(false))
    await dispatch(handleNewArgs(args))
})


export const handleDisposition = createAsyncThunk('customer/embedded/disposition', async (arg, thunkAPI) => {
    const {customer} = thunkAPI.getState()
    const caseId = customer.data.caseId
    thunkAPI.dispatch(handleEndCallTask())

    if (!caseId) {
        return
    }

    if (arg.description) {
        const res = await fetch(`${process.env.REACT_APP_CRM_NEW_BASE_URL}/api/v1/case_data/disposition`, {
            method: 'post',
            body: JSON.stringify({
                caseId: caseId,
                dispositionCode: arg.description
            }),
            headers: {'Content-Type': 'application/json'}
        })
        if (res.ok) {
            return true
        }
    }

    // throw Error(`could not disposition: ${arg.description}`)
})

export const getProfileInfo = createAsyncThunk('customer/embedded/profile', async (arg, thunkAPI) => {
    const {settings} = thunkAPI.getState()
    const url = `${process.env.REACT_APP_CRM_NEW_BASE_URL}/api/v1/profile/${settings.username}`
    const response = await fetch(url)
    if (response.ok) {
        return response.json()
    }
    throw Error(`could not find profile info for ${url}`)
})

export const embeddedSlice = createSlice({
    name: 'embedded',
    initialState: {
        embeddedShowNewCase: false,
        args: null,
        alvariaUserId: null,
        agentId: null
    },
    reducers: {
        setEmbeddedShowNewCase: (state, action) => {
            state.embeddedShowNewCase = action.payload
        }
    },
    extraReducers: builder => {
        const addCases = (asyncThunk, {fulfilled, pending, rejected}) => {
            if (fulfilled) {
                builder.addCase(asyncThunk.fulfilled, fulfilled)
            }
            if (pending) {
                builder.addCase(asyncThunk.pending, pending)
            }
            if (rejected) {
                builder.addCase(asyncThunk.rejected, rejected)
            }
        }

        addCases(handleNewArgs, {
            fulfilled: (state, action) => {
                state.embeddedShowNewCase = true
                state.args = action.payload
            }
        })

        addCases(handleEndCallTask, {
            fulfilled: (state, action) => {
                state.embeddedShowNewCase = false
                state.args = null
            }
        })

        addCases(getProfileInfo, {
            fulfilled: (state, action) => {
                if (action.payload) {
                    state.alvariaUserId = action.payload.alvaria_id
                    state.agentId = action.payload.agent_id
                }
            }
        })

    }
})

export const {setEmbeddedShowNewCase} = embeddedSlice.actions

export default embeddedSlice.reducer