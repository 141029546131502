import {useDispatch, useSelector} from 'react-redux'
import {Col, Form, Row} from 'react-bootstrap'
import {isBusiness, isInputValid, isMexico, isProblemWithTheBillingAddressPimCode, useLabels} from '../../functions'
//import {AddressCollection} from '../AddressCollection'
import {setPreviousBillingAddress} from '../../state/customerSlice'
import {connect} from '../Notes/wrapper'
import '../../components/AddressCollection/address-collection.scss'
import React from "react";
import {FormCheck, FormLabel} from '../Utilities/Form'
import {Email} from "../Utilities/GeneralComponents";


export const PreviousBillingAddressCollection = (props) => {
    const agreeToPreviousBillingAddressCreditCheck = useSelector(state => state.customer.data.agreeToPreviousBillingAddressCreditCheck)
    const labels = useLabels()
    const dispatch = useDispatch()
    const program = useSelector(state => state.customer.data.program)
    const epqPim = useSelector(state => state.customer.data.epqPim)
    const orderPim = useSelector(state => state.customer.data.orderPim)
    const script = useSelector(state => state.customer.data?.script?.toLowerCase())


    let key = 'previousBillingAddress'
    const previousBillingAddress = useSelector(state => state.customer.data[key])
    const addressValidation = useSelector(state => state.validation[key])

    const isFedexError = useSelector(state => state.customer.data.fedexError)
    const states = useSelector(state => {
        if (isMexico(state.customer.data.program)) {
            return state.settings.mxStates
        }
        return state.settings.usStates
    })

    const setAddress = (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        if (name === 'overrideAddress') {
            payload[name] = e.target.checked ? 'true' : 'false'
        }


        dispatch(setPreviousBillingAddress(payload))

    }
    // const setOverrideAddress = (e) => {
    //     dispatch(setPreviousBillingAddress({overrideAddress: e.target.checked ? 'true' : 'false'}))
    // }

    let businessFields
    if (isBusiness(script) && props.showPhoneNumber) {
        businessFields = <>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <FormLabel required={!isMexico(program)}
                                   bold={true}>{labels.prevBillingPrefix} {labels.companyName}</FormLabel>
                        <Form.Control isInvalid={!isInputValid(addressValidation, 'companyName')}
                                      value={previousBillingAddress.companyName} onChange={setAddress}
                                      name="companyName"/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Email/>
            </Row>
        </>
    }
    // useEffect(() => {
    //     if (isMexico(program)) {
    //         if (!previousBillingAddress.address) {
    //             dispatch(setPreviousBillingAddress({address: 'Casa 1'}));
    //         }
    //         if (!previousBillingAddress.zip) {
    //             dispatch(setPreviousBillingAddress({zip: '00000'}));
    //
    //         }
    //     }
    //
    // }, [dispatch, previousBillingAddress.address, previousBillingAddress.zip, program, props.type]);


    let overridePreviousBillingAddressComponent
    if (agreeToPreviousBillingAddressCreditCheck ) {
        //&& (isProblemWithTheBillingAddressPimCode(epqPim) || isProblemWithTheAddressPimCode(epqPim))
        // if (props.showOverride !== false && (isFedexError || isProblemWithTheBillingAddressPimCode(epqPim) || isProblemWithTheAddressPimCode(epqPim) )) {
        overridePreviousBillingAddressComponent = (
            <Form.Group>
                <FormCheck bold={true} type="checkbox" label={labels.addressOverride}
                           checked={previousBillingAddress.overrideAddress === 'true'}
                           onChange={(e) => setAddress(e)} name="overrideAddress"/>
            </Form.Group>
        )
    }


    return (
        <div className="address-collection">

            <Form>

                <Row className="mb-3">
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <FormLabel required={false}
                                       bold={true}>{labels.prevBillingPrefix} {labels.addressFirstName}</FormLabel>
                            <Form.Control value={previousBillingAddress.firstName} type="text"
                                          name="firstName" onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'firstName')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <FormLabel required={false}
                                       bold={true}>{labels.prevBillingPrefix} {labels.addressLastName}</FormLabel>
                            <Form.Control value={previousBillingAddress.lastName} type="text" name="lastName"
                                          onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'lastName')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {businessFields}
                {!isMexico(program) || (isMexico && props.showFullAddress) ?
                    <Row className="mb-3">
                        <Col>
                            <FormLabel required={true}
                                       bold={true}>{labels.prevBillingPrefix} {labels.serviceAddress}</FormLabel>
                            <Form.Control value={previousBillingAddress.address} type="text" name="address"
                                          onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'address')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Col>
                    </Row> : null}
                {!isMexico(program) || (isMexico && props.showFullAddress) ?
                    <Row className="mb-3">
                        <Col>
                            <FormLabel
                                bold={true}>{labels.prevBillingPrefix} {labels.serviceAddress2}</FormLabel>
                            <Form.Control value={previousBillingAddress.address2} type="text" name="address2"
                                          onChange={setAddress}/>
                        </Col>
                    </Row> : null}
                <Row className="mb-3">
                    <Col sm={12} md={4}>
                        <FormLabel required={true}
                                   bold={true}>{labels.prevBillingPrefix} {labels.city}</FormLabel>
                        <Form.Control value={previousBillingAddress.city} type="text" name="city" onChange={setAddress}
                                      isInvalid={!isInputValid(addressValidation, 'city')}/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={4}>
                        <FormLabel required={true}
                                   bold={true}>{labels.prevBillingPrefix} {labels.state}</FormLabel>
                        <Form.Select name="state" value={previousBillingAddress.state} onChange={setAddress}
                                     isInvalid={!isInputValid(addressValidation, 'state')}>
                            <option>Select state</option>
                            {states.map(s => <option key={s.code} value={s.code}>{s.name} ({s.code})</option>)}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                    {!isMexico(program) || (isMexico && props.showFullAddress) ?
                        <Col sm={12} md={4}>
                            <FormLabel required={true}
                                       bold={true}>{labels.prevBillingPrefix} {labels.zipCode}</FormLabel>
                            <Form.Control value={previousBillingAddress.zip} type="text" name="zip"
                                          onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'zip')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Col> : null}

                </Row>
            </Form>
            <Row className="mb-3">
                <Col>
                    {overridePreviousBillingAddressComponent}
                </Col>
            </Row>
        </div>
    )


    // let component = null
    //
    // if (agreeToPreviousBillingAddressCreditCheck) {
    //     component = <AddressCollection type="previousBilling" showOverride={props.showOverride}/>
    // }

// const onChange = (e) => {
//     const newVal = 'Yes' === e.target.value
//     dispatch(setIsServiceAndBillingAddressTheSame(newVal))
//     if (newVal) {
//         dispatch(setBillingAddress(serviceAddress))
//     }
// }

    // return <>
    {/*<Row className="mb-3">*/
    }
    {/*    <Col>*/
    }
    {/*        <Form.Group>*/
    }
    {/*            <FormLabel bold={true}>{labels.isServiceAndBillingAddressTheSame}</FormLabel>*/
    }
    {/*            <FormCheck type="radio" label={labels.yesOption} value="Yes" checked={isServiceAndBillingAddressTheSame}*/
    }
    {/*                        onChange={onChange}/>*/
    }
    {/*            <FormCheck type="radio" label="No" value="No" checked={!isServiceAndBillingAddressTheSame}*/
    }
    {/*                        onChange={onChange}/>*/
    }
    {/*        </Form.Group>*/
    }
    {/*    </Col>*/
    }
    {/*</Row>*/
    }
    {/*{component}*/
    }
    // </>
}

export default connect(PreviousBillingAddressCollection, 'address-collection')